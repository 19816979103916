<template>
  <div>
    <div style="text-align: center;height:60px ;padding: 20px;">
      <el-button v-if="major"  type="primary" :disabled="!enableExam" @click="examine">开始考试</el-button>
      
      <div v-if="major && !enableExam" style="font-size: 12px;color: #aaa;">(提示：当前专业课学习时间{{mins}}分钟，公需课学习时间{{gxkminsSum}}分钟，专业课学习时间达到{{majorMins}}分钟且公需课学习时间达到{{gxkMins}}分钟才可以考试)</div>
     
      <!-- 公需课至少600分钟才可以考试 -->
      <!-- <div v-if="major && !enableExam" style="font-size: 12px;color: #aaa;">(提示：当前专业课学习时间{{mins}}分钟，公需课学习时间{{gxkminsSum}}分钟，
        专业课学习时间达到600分钟且公需课学习时间达到{{gxkMins}}分钟才可以考试,专业课超过600分钟考试及格可获得15学分,专业课超过1200分钟考试及格可获得20学分,专业课超过1800分钟考试及格可获得25学分,专业课超过2400分钟考试及格可获得30学分)</div>
      -->
      <div v-if="enableExam && major!=0" style="font-size: 12px;color: #aaa;">(提示：当前专业课学习时间{{mins}}分钟，公需课学习时间{{gxkminsSum}}分钟，学时认证{{xsrz}}分钟，考试及格后可获得{{credits}}学分)</div>
    </div>
    <template v-for="item in datalist" :key="item.id">
      <div @click="examDetail(item.id)" style="margin:10px;cursor:pointer">
          <div>考试成绩：{{item.score}}</div>
          <div>考试时间：{{item.submitTime}}</div>
      </div>
      <div style="height:2px;background-color: #eee;"></div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datalist: [],
      mins: 0,
      major: '',
      majorMins: 0,
      credits: 0,
      enableExam: false,
      gxkminsSum: 0,
      gxkMins: 0,
      xsrz: 0
    };
  },
  mounted: function () {
    this.$net.examState().then(({ data })=>{
      this.mins = data.mins ,
      this.major = data.major,
      this.majorMins = data.majorMins,
      this.credits = data.credits,
      this.xsrz = data.xsrz,
      //根据时长获取学分
      // this.credits =  data.mins >= 2400 ? 30 : data.mins >= 1800 ? 25 :  data.mins >= 1200 ? 20 :  data.mins >= 600 ? 15 :  15
      this.gxkminsSum = data.gxkminsSum,
      this.gxkMins = data.gxkMins,
      //公需课时长到位 专业课时长到位
      this.enableExam = data.mins >= data.majorMins && data.gxkminsSum >= data.gxkMins
      this.enableExam = data.mins +data.gxkminsSum + data.xsrz >= data.majorMins + data.gxkMins
      //公需课时长到1200分 专业课至少要600分钟才可以考试
      // this.enableExam = data.mins >= 600 && data.gxkminsSum >= data.gxkMins
    })
    this.$net.examList().then(({ data }) => {
      this.datalist = data.list
    });
  },
  methods:{
    examDetail (id) {
      this.$router.push({path:'/exam/'+id})
    },
    examine () {
      this.$net.examLast().then(({ data })=>{
        let last = data.exam
        if(!last || last.submitTime){
          console.log('新考试')
          this.startNewExam()
        }else{
          console.log('旧考试')
          this.openExamine(last.id)
        }
      })
    },
    startNewExam () {
      this.$net.examStart().then(({ data }) => {
        if(data.code > 0){
          return this.$alert(data.msg)
        }
        this.openExamine(data.exam.id)
      });
    },
    openExamine (id) {
        this.$router.push({path:'/examine/'+id})
    }
  }
};
</script>